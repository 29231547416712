import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import algoliasearch from 'algoliasearch/lite';
import Cookies from 'js-cookie';

import { CartContext } from './CartContext';

interface Dealer {
  id: number;
  name: string;
  slug: string;
  email: string;
  phone: string;
  address: {
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    latitude: number;
    longitude: number;
  };
  isCOS: boolean;
  mainServiceArea: string;
  areasOfService: {
    children: {
      text: string;
    }[];
  };
  internal: {
    contentDigest: string;
    type: string;
    owner: string;
  };
  [key: string]: any;
}

interface DealerSearchContextType {
  dealerSearchActive: boolean;
  dealerSearchResults: Dealer[];
  setDealerSearchActive: (active: boolean) => void;
  toggleDealerSearch: () => void;
  getDealerSearchResults: (zipcode: string) => Promise<void>;
  deleteDealerSearchResults: () => void;
  firstLoad: boolean;
  setFirstLoad: (val: boolean) => void;
  loading: boolean;
  storedDealer: Dealer | null;
  fetchStoredDealerData: () => Promise<void>;
  setDealerSearchResults: (results: Dealer[]) => void;
  setDealerGateModalActive: (active: boolean) => void;
  dealerGateModalActive: boolean;
  dealerSearchDisplayMode: string;
  setDealerSearchDisplayMode: (mode: string) => void;
  setStoredDealer: (dealer: Dealer | null) => void;
  storedDealerPanelVisible: boolean;
  setStoredDealerPanelVisible: (visible: boolean) => void;
}

interface DealerSearchProviderProps {
  children: ReactNode;
}

// Remove these when CartContext has types
type CartAttribute = {
  key: string;
  value: string;
};

type Cart = {
  id?: string;
};

interface CartContextProps {
  cart?: Cart;
  updateCartAttributes?: (attributes: CartAttribute[]) => Promise<void>;
}

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

// select algolia index
const dealerInformationIndex = client.initIndex('dealers');
const DealerSearchContext = createContext<DealerSearchContextType | undefined>(
  undefined
);

function DealerSearchProvider({
  children,
}: Readonly<DealerSearchProviderProps>) {
  const cartContext = useContext(CartContext) as CartContextProps;
  const [dealerSearchActive, setDealerSearchActive] = useState(false);
  const [dealerGateModalActive, setDealerGateModalActive] = useState(false);
  const [dealerSearchResults, setDealerSearchResults] = useState([]);
  const [dealerSearchDisplayMode, setDealerSearchDisplayMode] = useState('');
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [storedDealer, setStoredDealer] = useState<Dealer | null>(null);
  const [storedDealerPanelVisible, setStoredDealerPanelVisible] =
    useState(false);

  const fetchStoredDealerData = async () => {
    const dealerId = Cookies.get('dealerId');
    try {
      // fetch dealer data from Algolia
      const response = await dealerInformationIndex.search<Dealer>(
        dealerId || '',
        {
          restrictSearchableAttributes: ['id'],
        }
      );

      if (response.hits.length) {
        setStoredDealer(response.hits[0] as Dealer);
        setDealerGateModalActive(false);
        setDealerSearchDisplayMode('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // does cookie have store dealerId? Populate storedDealer
    // with fresh dealer data from Algolia
    if (typeof window !== 'undefined' && Cookies.get('dealerId')) {
      (async () => {
        await fetchStoredDealerData();
      })();
    }
  }, []);

  useEffect(() => {
    // if firstLoad is true, delete dealerSearchResults
    if (firstLoad) {
      setDealerSearchResults([]);
    }
  }, [firstLoad]);

  useEffect(() => {
    const updateDealerInCart = async () => {
      if (cartContext?.cart?.id && storedDealer?.id) {
        const attributes = [
          {
            key: 'Dealer ID',
            value: storedDealer?.id?.toString() || '',
          },
        ];

        await cartContext?.updateCartAttributes(attributes);
      }
    };
    updateDealerInCart();
  }, [storedDealer, cartContext?.cart?.id]);

  const getDealerSearchResults = async (zipcode: string) => {
    setFirstLoad(false);
    setLoading(true); // spinner hook
    try {
      const response = await fetch(
        `${process.env.GATSBY_POSTCODE_SEARCH_API}?postalCode=${zipcode}`,
        {
          method: 'GET',
        }
      );

      setDealerSearchResults(await response.json());

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const toggleDealerSearch = () => {
    setDealerSearchActive(!dealerSearchActive);
  };

  const deleteDealerSearchResults = () => {
    setDealerSearchResults([]);
  };

  const value = useMemo(
    () => ({
      dealerSearchActive,
      dealerSearchResults,
      setDealerSearchActive,
      toggleDealerSearch,
      getDealerSearchResults,
      deleteDealerSearchResults,
      firstLoad,
      setFirstLoad,
      loading,
      storedDealer,
      fetchStoredDealerData,
      setDealerSearchResults,
      setDealerGateModalActive,
      dealerGateModalActive,
      dealerSearchDisplayMode,
      setDealerSearchDisplayMode,
      setStoredDealer,
      storedDealerPanelVisible,
      setStoredDealerPanelVisible,
    }),
    [
      dealerSearchResults,
      dealerSearchActive,
      storedDealer,
      setFirstLoad,
      fetchStoredDealerData,
      dealerSearchDisplayMode,
      storedDealerPanelVisible,
    ]
  );

  return (
    <DealerSearchContext.Provider value={value}>
      {children}
    </DealerSearchContext.Provider>
  );
}

export { DealerSearchProvider, DealerSearchContext };
