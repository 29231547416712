import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import { CartProvider } from '@rsc/contexts/CartContext';
import CustomerContextProvider from '@rsc/contexts/CustomerContext';
import { DealerSearchProvider } from '@rsc/contexts/DealerSearchContext';
import { LocaleProvider } from '@rsc/contexts/LocaleContext';
import { SiteSettingsProvider } from '@rsc/contexts/SiteSettingsContext';
import { LocaleCurrencyEnum } from '@rsc/models/LocaleCurrencyEnum';
import { RechargeSubscriptionsProvider } from '@rsc/contexts/RechargeSubscriptionsContext';
import useSiteSettings from '@components/hooks/useSiteSettings';

import { getTranslationCache } from '@rsc/sanity-static-localization-provider-library';
import {
  InternationalContext,
  useInternationalHelper,
} from '@rsc/gatsby-localization-helpers';
import { uiComponentsLocalizationData } from '@rsc/static-translations';
import theme from './src/components/theme';

const InternationalContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const mockTranslationCache = getTranslationCache(
    null,
    uiComponentsLocalizationData
  );

  const staticContentLocalizer = useInternationalHelper(
    'en-us',
    mockTranslationCache,
    uiComponentsLocalizationData
  );

  return (
    <InternationalContext.Provider value={staticContentLocalizer}>
      {children}
    </InternationalContext.Provider>
  );
};

function RootWrapper({ element }: { element: React.ReactElement }) {
  return (
    <ThemeProvider theme={theme}>
      <InternationalContextWrapper>
        <SiteSettingsProvider
          useSiteSettings={useSiteSettings}
          siteIdentifier="ifb"
        >
          <LocaleProvider
            allowedCurrencies={[LocaleCurrencyEnum.USD, LocaleCurrencyEnum.CAD]}
          >
            <CustomerContextProvider>
              <RechargeSubscriptionsProvider
                subscriptionsMenuLabel="Location/Tracking Subscriptions"
                cancelSubscriptionMessage={
                  <p>
                    All future billing of this subscription will be canceled.
                    <br />
                    You can start a new subscription at any time by selecting
                    Activate Collar in the Invisible Fence app.
                  </p>
                }
              >
                <CartProvider disableProductTagsOnCheckout>
                  <DealerSearchProvider>{element}</DealerSearchProvider>
                </CartProvider>
              </RechargeSubscriptionsProvider>
            </CustomerContextProvider>
          </LocaleProvider>
        </SiteSettingsProvider>
      </InternationalContextWrapper>
    </ThemeProvider>
  );
}

export default RootWrapper;
